import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { use100vh } from "react-div-100vh";
import Modal from "react-modal";
import { RemoveScroll } from "react-remove-scroll";
import { useKeyPressEvent } from "react-use";

// Slideshow
import { Fade } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";

// Icons
import { CloseIcon } from "../icons/close-icon";

const LightboxContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;

  background-color: #fff;

  z-index: 100;
  height: 100%;
  width: 100%;

  & .container {
    height: calc(${(props) => props.height}px - 200px);
    width: 100%;

    & .lightbox-wrapper {
      position: relative;
      width: 100%;
      height: 100%;
    }
  }

  & .single-image-container {
    padding: 0 140px;

    @media (max-width: 800px) {
      padding: 0 70px;
    }

    @media (max-width: 500px) {
      padding: 0 15px;
    }
  }

  & img {
    width: 100%;
    height: 100%;
    object-fit: contain !important;

    &.landscape {
    }

    &.portrait {
    }
  }
`;

const GalleryContainer = styled.div`
  position: relative;

  width: 100%;
  height: 100%;
  max-height: 100%;

  & > div,
  & .react-slideshow-container,
  & .react-slideshow-fade-wrapper,
  & .react-slideshow-fade-images-wrap,
  & .react-slideshow-fade-images-wrap > div,
  & .single-image-container {
    position: relative;

    width: 100%;
    height: 100%;
  }

  & img {
    object-fit: contain !important;
    width: 100%;
    height: 100%;
  }
`;

const CloseLightbox = styled.button`
  position: fixed;
  top: 0;
  left: 0;

  z-index: 200;

  padding: 20px;
  cursor: pointer;

  @media (max-width: 800px) {
    padding: 15px;
  }
`;

const Arrow = styled.button`
  position: absolute;
  top: 0;
  bottom: 0;
  width: calc(50% - 20px);

  z-index: 100;

  &.previous {
    left: 20px;

    cursor: url(/icons/arrow-prev@1x.png) 9 9, pointer !important;
    cursor: -webkit-image-set(
          url(/icons/arrow-prev@1x.png) 1x,
          url(/icons/arrow-prev@2x.png) 2x
        )
        9 9,
      pointer !important;
  }

  &.next {
    right: 20px;

    cursor: url(/icons/arrow-next@1x.png) 9 9, pointer !important;
    cursor: -webkit-image-set(
          url(/icons/arrow-next@1x.png) 1x,
          url(/icons/arrow-next@2x.png) 2x
        )
        9 9,
      pointer !important;
  }

  @media (hover: none) and (pointer: coarse) {
    display: none;
  }
`;

Modal.setAppElement("#___gatsby");

export const Lightbox = ({
  images,
  isLightboxOpen,
  setIsLightboxOpen,
  activeSlideNumber,
}) => {
  const height = use100vh();

  // Slideshow Reference
  const slideRef = useRef();

  useEffect(() => {
    if (typeof window !== undefined) {
      document.addEventListener("keydown", (event) => onEscape(event));
    }
  }, [isLightboxOpen]);

  const onEscape = (event) => {
    if (event.keyCode === 27 && isLightboxOpen === true) {
      setIsLightboxOpen(false);
    }
  };

  // Keyboard navigation events for slideshow
  const goBack = () => {
    if (slideRef.current !== undefined && artworkImages.length > 1) {
      slideRef.current.goBack();
    }
  };

  const goNext = () => {
    if (slideRef.current !== undefined && artworkImages.length > 1) {
      slideRef.current.goNext();
    }
  };
  useKeyPressEvent("ArrowRight", goNext);
  useKeyPressEvent("ArrowLeft", goBack);

  const artworkImages = images.map((image, index) => {
    return (
      <div
        className="single-image-container"
        key={`single_page_item_slideshow_${index}`}
      >
        <img
          srcSet={image.image.fluid.srcSet}
          src={image.image.fluid.src}
          alt={image.image.alt}
          loading={`lazy`}
        />
      </div>
    );
  });

  return (
    <RemoveScroll>
      <Modal
        isOpen={isLightboxOpen}
        portalClassName={`ReactModalPortal-gallery`}
        className="artwork-lighbox-modal"
        contentLabel={`Artwork Gallery`}
        shouldFocusAfterRender={true}
      >
        <LightboxContainer height={height}>
          <CloseLightbox onClick={() => setIsLightboxOpen(false)}>
            <CloseIcon />
          </CloseLightbox>

          <div className="container">
            <div className="lightbox-wrapper">
              <GalleryContainer>
                {artworkImages.length > 1 && (
                  <Arrow
                    aria-label="Previous Slide"
                    className={`previous`}
                    onClick={() => {
                      slideRef.current.goBack();
                    }}
                  />
                )}
                <Fade
                  ref={slideRef}
                  arrows={false}
                  transitionDuration={0}
                  pauseOnHover={false}
                  autoplay={false}
                  infinite={true}
                  canSwipe={artworkImages.length > 1 ? true : false}
                  defaultIndex={activeSlideNumber}
                >
                  {artworkImages}
                </Fade>
                {artworkImages.length > 1 && (
                  <Arrow
                    aria-label="Next Slide"
                    className={`next`}
                    onClick={() => {
                      slideRef.current.goNext();
                    }}
                  />
                )}
              </GalleryContainer>
            </div>
          </div>
        </LightboxContainer>
      </Modal>
    </RemoveScroll>
  );
};
