import React from "react";

export const InfoIcon = ({ fill }) => (
  <svg
    width="29"
    height="30"
    viewBox="0 0 29 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.4397 22.6383V22.6296L17.4497 22.6396C17.4464 22.6391 17.4431 22.6387 17.4397 22.6383Z"
      fill={fill}
    />
    <path
      d="M17.4397 23.2996V22.6383C16.2665 22.4881 15.6997 22.237 15.6997 21.3396V11.0896H15.4697L11.7197 12.3896V12.9596C12.9397 13.0596 13.5397 13.2396 13.5397 14.3596V21.3296C13.5397 22.2296 12.9597 22.4796 11.7897 22.6296V23.2996H17.4397Z"
      fill={fill}
    />
    <path
      d="M14.3597 4.80957C13.4597 4.80957 12.7397 5.54957 12.7397 6.42957C12.7397 7.35957 13.4697 8.07957 14.3597 8.07957C15.2497 8.07957 15.9797 7.34957 15.9797 6.42957C15.9797 5.55957 15.2597 4.80957 14.3597 4.80957Z"
      fill={fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.5 0.599609C6.49187 0.599609 0 7.09148 0 15.0996C0 23.1077 6.49187 29.5996 14.5 29.5996C22.5081 29.5996 29 23.1077 29 15.0996C29 7.09148 22.5081 0.599609 14.5 0.599609ZM1 15.0996C1 7.64377 7.04416 1.59961 14.5 1.59961C21.9558 1.59961 28 7.64377 28 15.0996C28 22.5555 21.9558 28.5996 14.5 28.5996C7.04416 28.5996 1 22.5555 1 15.0996Z"
      fill={fill}
    />
  </svg>
);
