import React, { useState, useContext, useEffect } from "react";
import { graphql, Link } from "gatsby";
import styled from "styled-components";
import { PrismicRichText } from "@prismicio/react";

// Previews
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";

// Context
import { PageContext } from "../components/context/page-context";

// SEO
import { PageSeo } from "../components/seo/page-seo";

const Page = styled.div`
  color: #fff;
  padding: 120px 0 30px 0;

  @media (max-width: 768px) {
    padding: 90px 0 30px 0;
  }

  & .image-container {
    max-width: 510px;
    margin: 0 auto 60px auto;

    @media (max-width: 768px) {
      margin: 0 auto 30px auto;
    }
  }

  & .text-container {
    max-width: 700px;
    margin: 0 auto;
  }

  & .social-links {
    margin: 30px auto;

    & ol {
      border-top: 1px solid #fff;
      border-bottom: 1px solid #fff;

      padding: 15px 0;

      & li {
        display: grid;
        grid-template-columns: 90px auto;
        grid-column-gap: 10px;

        padding: 8px 0;

        & p {
          margin: 0;
        }

        & .website {
          font-variant: all-small-caps;

          // & span {
          //   display: none;
          // }
        }

        & a {
          color: #fff;
        }

        @media (max-width: 768px) {
          display: block;
          grid-template-columns: unset;
          grid-column-gap: unset;

          & p {
            &:last-of-type {
              padding: 0 0 0 20px;
            }
          }
        }
      }
    }
  }

  & .typeface-notes {
    & h2 {
      font-size: 18px;
      line-height: 23px;
      text-align: center;
    }
  }

  // @media (max-width: 768px) {
  //   & .social-links {
  //     & ol {
  //       & li {
  //         & p {
  //           &.website {
  //             & span {
  //               display: inline-block;
  //             }
  //           }
  //         }
  //       }
  //     }
  //   }
  // }
`;

const Info = ({ data }) => {
  const [pageContext, setPageContext] = useContext(PageContext);

  useEffect(() => {
    setPageContext({
      pageTitle: data.prismicAbout.data.title.text,
      pageColor: `#424859`,
      pageType: `info`,
    });
  }, [data]);

  const socialLinks = data.prismicAbout.data.social_links.map((link, index) => (
    <li key={`single_social_link_${index}`}>
      <p className="website tag">
        {link.website}
        {/* <span className="mobile-colon">:</span> */}
      </p>
      <PrismicRichText field={link.link.richText} />
    </li>
  ));

  return (
    <>
      <PageSeo
        seoTitle={`${data.prismicAbout.data.title.text} - George Vasey`}
        seoText={null}
        seoImage={null}
      />

      <Page>
        <div className="image-container">
          {data.prismicAbout.data.image.fluid !== null && (
            <img
              srcSet={data.prismicAbout.data.image.fluid.srcSet}
              src={data.prismicAbout.data.image.fluid.src}
              alt={data.prismicAbout.data.image.alt}
              loading="lazy"
            />
          )}

          <div className="caption">
            <PrismicRichText
              field={data.prismicAbout.data.image_caption.richText}
            />
          </div>
        </div>

        <div className="text-container">
          <PrismicRichText field={data.prismicAbout.data.text.richText} />
        </div>

        <div className="text-container social-links">
          <ol>{socialLinks}</ol>
        </div>

        <div className="text-container typeface-notes">
          <h2>A Note on the Types</h2>
          <PrismicRichText field={data.prismicAbout.data.typefaces.richText} />
        </div>
      </Page>
    </>
  );
};

export const query = graphql`
  {
    prismicAbout {
      _previewable
      data {
        title {
          text
        }
        image {
          alt
          fluid {
            srcSet
            src
          }
        }
        image_caption {
          richText
        }
        text {
          richText
        }
        social_links {
          website
          link {
            richText
          }
        }
        typefaces {
          richText
        }
      }
    }
  }
`;

export default withPrismicPreview(Info);
