import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { StaticQuery, graphql, Link } from "gatsby";

// Icons
import { InfoIcon } from "../icons/info-icon";

const Nav = styled.nav`
  display: grid;
  grid-template-columns: 50px 1fr 50px;

  width: 100%;

  font-size: 30px;
  line-height: 80px;

  & .info-container {
    & a {
      & svg {
        & path {
          transition: 300ms fill ease;
        }
      }

      &:hover {
        & svg {
          & path {
            fill: #b3b3b3;
          }
        }
      }
    }
  }

  & .navigation-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    & .spacer {
      margin: 0 30px;
      line-height: 1;
      height: 15px;
    }

    & .inner-menu-container {
      & a {
        margin: 0 30px 0 0;

        &:last-of-type {
          margin: 0;
        }

        transition: 300ms color ease;

        &.current,
        &:hover {
          color: #b3b3b3;
        }
      }
    }
  }
`;

export const DesktopMenu = ({ pageType }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <Nav>
      <div className="info-container">
        <Link to="/about/">
          <InfoIcon fill={pageType === `info` ? `#fff` : `#000`} />
        </Link>
      </div>
      <div className="navigation-container">
        <Link to={`/`}>George Vasey</Link>
        <p className="spacer">*</p>

        <div
          className="menu-container"
          onMouseLeave={() => setIsMenuOpen(false)}
        >
          {isMenuOpen ? (
            <div className="inner-menu-container">
              <Link to={`/writing/`} activeClassName="current" partiallyActive>
                Writing
              </Link>
              <Link to={`/curating/`} activeClassName="current" partiallyActive>
                Curating
              </Link>
              <Link
                to={`/conversations/`}
                activeClassName="current"
                partiallyActive
              >
                Conversations
              </Link>
              <Link to={`/artists/`} activeClassName="current" partiallyActive>
                Artists
              </Link>
            </div>
          ) : (
            <button onMouseEnter={() => setIsMenuOpen(true)}>
              Curator and Writer
            </button>
          )}
        </div>
      </div>
    </Nav>
  );
};
