import React from "react";
import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
body {
  font-family: "Mercure-Regular", "Helvetica Neue", "Lucida Grande", sans-serif;
  font-weight: normal;
  font-style: normal;

  font-size: 18px;
  line-height: 23px;

  text-shadow: 1px 1px 1px rgba(0, 0, 0, .004);
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
  -webkit-tap-highlight-color: transparent;

  color: #000;
  margin: 0;

  // word-break: break-word;

  transition: 350ms background-color ease;

  @media(max-width: 1100px){
    font-size: 15px;
    line-height: 21px;
  }
}

input {
  border-radius: 0;
}

h1,h2,h3,h4,h5{
  font-family: "Mercure-Regular", "Helvetica Neue", "Lucida Grande", sans-serif;
  font-weight: normal;

  margin: 0;
}


/* Title 1 */
h1{
  font-size: 28px;
  line-height: 34px;

  @media(max-width: 1100px){
    font-size: 23px;
    line-height: 28px;
  }
}

/* Title 2 */
h2 {
  font-size: 21px;
  line-height: 28px;

}

/* Heading 1 */
h3 {
  font-size: 30px;
  line-height: 32px;
}

/* Heading 2 */
h4 {
  font-size: 18px;
  line-height: 24px;
}

/* Heading 3 */
h5 {
  font-size: 12px;
  line-height: 14px;
}

strong{
  font-weight:normal;
  font-style:normal;
}


/*--------------------------------------------------------------
  ## Media
  --------------------------------------------------------------*/

video,
img {
  width: 100%;
  margin: 0;

  display: block;
}

/*--------------------------------------------------------------
  ## Buttons
  --------------------------------------------------------------*/

  button{
    background-color: transparent;
    border: 0;
    margin: 0;
    padding: 0;

    appearance:none;

    &:focus,
    &:hover{
      outline: none;
    }
  }

/*--------------------------------------------------------------
  ## Links
  --------------------------------------------------------------*/
a {
  color: inherit;
  text-decoration: none;

  position: relative;
}

a:visited {
  color: inherit;
}

a:hover,
a:focus,
a:active {
  color: inherit;
}

a:hover,
a:active,
a:focus {
  outline: 0;
}

/*--------------------------------------------------------------
  ## Text
  --------------------------------------------------------------*/

p {
  margin: 1em 0;

  & a {
    color: #bf4945;
  }
}

em,
i {
  font-family: "Mercure-Italic", "Helvetica Neue", "Lucida Grande", sans-serif;
  font-style: normal;
}

pre {
  font-family: "Mercure-Regular", "Helvetica Neue", "Lucida Grande", sans-serif;
  font-weight: normal;
  font-style: normal;

  font-size: 21px;
  line-height: 28px;

  text-shadow: 1px 1px 1px rgba(0, 0, 0, .004);
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
  -webkit-tap-highlight-color: transparent;

  color: #000;
  margin: 0;

  word-break: break-word;
  white-space: break-spaces !important;

  padding: 0 0 0 60px;

  @media (max-width: 1100px) {
    font-size: 15px;
    line-height: 20px;

    padding: 0 0 0 20px;
  }
}

ol, ul, li{
  list-style: none;
  padding: 0;
  margin: 0;
}

.tag {
  letter-spacing: 1px;
  text-transform: uppercase;
}

.caption,
.caption > p {
  font-size: 14px;
  text-align: center;

  @media(max-width: 1100px){
    font-size: 12px;
    line-height: 16px;
  }
}

/*--------------------------------------------------------------
  ## Box Sizing / Layout
  --------------------------------------------------------------*/

div {
  box-sizing: border-box;
}


/*--------------------------------------------------------------
  ## React Modal
--------------------------------------------------------------*/
.ReactModalPortal{
  position: fixed;

  top:0;
  left:0;
  right:0;
  bottom:0;

  z-index: 100;
}

.ReactModalPortal-gallery{
  position: fixed;

  top:0;
  left:0;
  right:0;
  bottom:0;

  z-index: 10000;
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 250ms ease;
  background-color: #fff;
}

.ReactModal__Overlay--after-open{
  opacity: 1;
}

.ReactModal__Overlay--before-close{
  opacity: 0;
}

.ReactModal__Content{
  width: 100%;
  height: 100%;
}
`;

export default GlobalStyle;
