import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { Helmet } from "react-helmet";

export const DefaultSEO = () => {
  const data = useStaticQuery(graphql`
    {
      prismicSeo {
        data {
          seo_title
          seo_description
          seo_image {
            url
          }
        }
      }
    }
  `);

  return (
    <Helmet
      title={data.prismicSeo.data.seo_title}
      htmlAttributes={{ lang: "en" }}
      meta={[
        {
          name: "viewport",
          content:
            "width=device-width, user-scalable=no, initial-scale=1, maximum-scale=1",
        },
        {
          name: "description",
          content: `${data.prismicSeo.data.seo_description}`,
        },
        {
          name: "og:image",
          content: `${data.prismicSeo.data.seo_image.url}`,
        },
        {
          name: "og:image:secure_url",
          content: `${data.prismicSeo.data.seo_image.url}`,
        },
        {
          name: "og:description",
          content: `${data.prismicSeo.data.seo_description}`,
        },
        {
          name: "og:image:width",
          content: `1200`,
        },
        {
          name: "og:image:height",
          content: `630`,
        },
        {
          name: "og:locale",
          content: `en`,
        },
        {
          name: "twitter:title",
          content: data.prismicSeo.data.seo_title,
        },
        {
          name: "twitter:description",
          content: `${data.prismicSeo.data.seo_description}`,
        },
        {
          name: "twitter:card",
          content: `summary_large_image`,
        },
        {
          name: "twitter:image",
          content: `${data.prismicSeo.data.seo_image.url}`,
        },
      ]}
      link={[
        {
          rel: "preload",
          href: "/fonts/fonts.css",
          as: "style",
        },
        {
          rel: "dns-prefetch",
          href: "https://images.prismic.io/georgevasey",
        },
        {
          rel: "preconnect",
          href: "https://images.prismic.io/georgevasey",
        },
        // {
        //   rel: "dns-prefetch",
        //   href: "https://player.vimeo.com",
        // },
        // {
        //   rel: "preconnect",
        //   href: "https://player.vimeo.com",
        // },
        // {
        //   rel: "dns-prefetch",
        //   href: "https://i.vimeocdn.com",
        // },
        // {
        //   rel: "preconnect",
        //   href: "https://i.vimeocdn.com",
        // },
        // {
        //   rel: "dns-prefetch",
        //   href: "https://f.vimeocdn.com",
        // },
        // {
        //   rel: "preconnect",
        //   href: "https://f.vimeocdn.com",
        // },
        {
          rel: "preload",
          href: "/fonts/891095a1-9a8e-45a4-87f3-2dc163037d89.woff2",
          as: "font",
          crossOrigin: "anonymous",
        },
        {
          rel: "preload",
          href: "/fonts/9dbb4ac2-580b-476c-8313-8cc1d6eeba4b.woff",
          as: "font",
          crossOrigin: "anonymous",
        },
        {
          rel: "preload",
          href: "/fonts/98052579-0648-4e02-953f-ba7b46d13355.woff2",
          as: "font",
          crossOrigin: "anonymous",
        },
        {
          rel: "preload",
          href: "/fonts/2bc28ff1-0120-47a3-8c4e-5864479e1a55.woff",
          as: "font",
          crossOrigin: "anonymous",
        },
        {
          rel: "preload",
          href: "/fonts/33283b97-0ab4-4de0-8545-e30d3d4db8c0.woff2",
          as: "font",
          crossOrigin: "anonymous",
        },
        {
          rel: "preload",
          href: "/fonts/5b5cdc4e-4474-4257-b3fb-1c9e40f7bd08.woff",
          as: "font",
          crossOrigin: "anonymous",
        },
        {
          rel: "preload",
          href: "/fonts/Mercure-Regular.woff2",
          as: "font",
          crossOrigin: "anonymous",
        },
        {
          rel: "preload",
          href: "/fonts/Mercure-Regular.woff",
          as: "font",
          crossOrigin: "anonymous",
        },
        {
          rel: "preload",
          href: "/fonts/Mercure-Italic.woff2",
          as: "font",
          crossOrigin: "anonymous",
        },
        {
          rel: "preload",
          href: "/fonts/Mercure-Italic.woff",
          as: "font",
          crossOrigin: "anonymous",
        },
        {
          rel: "preload",
          href: "/fonts/Mercure-Transcript.woff2",
          as: "font",
          crossOrigin: "anonymous",
        },
        {
          rel: "preload",
          href: "/fonts/Mercure-Transcript.woff",
          as: "font",
          crossOrigin: "anonymous",
        },
      ]}
    ></Helmet>
  );
};
