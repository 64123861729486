import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import { use100vh } from "react-div-100vh";
import { RemoveScroll } from "react-remove-scroll";
import { OpenMenu } from "../icons/menu-toggle";
import { CloseIcon } from "../icons/close-icon";

const Nav = styled.nav`
  width: 100%;
  padding: 0 35px;

  &.open {
    color: #fff;
    background-color: #424859;
  }

  &.closed {
    background-color: #fff;
  }

  & .navigation-container {
    width: 100%;

    & .top-level-navigation {
      display: grid;
      grid-template-columns: 50px 1fr 50px;

      width: 100%;

      & a {
        text-align: center;
        font-size: 22px;
      }

      & button {
        text-align: left;

        & svg {
          & path {
            fill: #fff;
          }
        }
      }
    }

    & .menu-container {
      display: flex;
      flex-direction: column;
      justify-content: center;

      padding: 0 0 65px 0;

      height: calc(${(props) => props.height}px - 65px);
    }

    & .inner-menu-container {
      & a {
        display: block;
        width: fit-content;

        margin: 0 auto;

        font-size: 28px;
        line-height: 40px;

        transition: 300ms color ease;

        &.current {
          color: #b48c32;
        }
      }
    }
  }
`;

export const MobileMenu = ({ pageType, location }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const height = use100vh();

  useEffect(() => {
    setIsMenuOpen(false);
  }, [location]);

  return (
    <Nav className={isMenuOpen ? `open` : `closed`} height={height}>
      <div className="navigation-container">
        <div className="top-level-navigation">
          <button onClick={() => setIsMenuOpen(!isMenuOpen)}>
            {isMenuOpen ? <CloseIcon /> : <OpenMenu />}
          </button>

          <Link to={`/`}>George Vasey</Link>
        </div>

        {isMenuOpen && (
          <RemoveScroll>
            <div className="menu-container">
              <div className="inner-menu-container">
                <Link to={`/about/`} activeClassName="current" partiallyActive>
                  About
                </Link>
                <Link
                  to={`/writing/`}
                  activeClassName="current"
                  partiallyActive
                >
                  Writing
                </Link>
                <Link
                  to={`/curating/`}
                  activeClassName="current"
                  partiallyActive
                >
                  Curating
                </Link>
                <Link
                  to={`/conversations/`}
                  activeClassName="current"
                  partiallyActive
                >
                  Conversations
                </Link>
                <Link
                  to={`/artists/`}
                  activeClassName="current"
                  partiallyActive
                >
                  Artists
                </Link>
              </div>
            </div>
          </RemoveScroll>
        )}
      </div>
    </Nav>
  );
};
