import React from "react";

export const CloseIcon = () => (
  <svg
    width="23"
    height="23"
    viewBox="0 0 23 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.5913 10.9087L0 21.5L0.707107 22.2071L11.2984 11.6158L21.8897 22.2071L22.5968 21.5L12.0055 10.9087L22.2071 0.707107L21.5 0L11.2984 10.2016L1.09676 0L0.389648 0.707107L10.5913 10.9087Z"
      fill="#1D1D1B"
    />
  </svg>
);
