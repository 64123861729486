import * as React from "react";
import {
  PrismicPreviewProvider,
  componentResolverFromMap,
} from "gatsby-plugin-prismic-previews";

// // Page  Transitions
// import { AnimatePresence } from "framer-motion";

// Prismic
import { linkResolver } from "./src/components/link-resolver/linkResolver";

// Pages
import Index from "./src/pages/index";
import Info from "./src/pages/about";

// Templates
import Conversation from "./src/templates/conversation";
import Curating from "./src/templates/curating";
import Writing from "./src/templates/writing";

export const wrapRootElement = ({ element }) => (
  <PrismicPreviewProvider
    repositoryConfigs={[
      {
        repositoryName: process.env.GATSBY_PRISMIC_REPO_NAME,
        linkResolver,
        componentResolver: componentResolverFromMap({
          about: Info,
          index: Index,
          conversation: Conversation,
          curating: Curating,
          writing: Writing,
        }),
      },
    ]}
  >
    {element}
  </PrismicPreviewProvider>
);

// export const wrapPageElement = ({ element }) => (
//   <AnimatePresence exitBeforeEnter>{element}</AnimatePresence>
// );
