import React, { useState, useEffect } from "react";
import { Location } from "@reach/router";
import styled from "styled-components";
import { Normalize } from "styled-normalize";

import { Link } from "gatsby";
import { PrismicProvider } from "@prismicio/react";
import { linkResolver } from "../link-resolver/linkResolver";

// Page  Transitions
import { motion, AnimatePresence } from "framer-motion";

// Components
import { Header } from "./header";

// Context
import { PageContext } from "../context/page-context";

// Styles
import GlobalStyles from "../styles/globalStyles";

// Components
import { DefaultSEO } from "./default-seo";

const Container = styled.div`
  position: relative;
  padding: 0 170px;

  @media (max-width: 1200px) {
    padding: 0 120px;
  }

  @media (max-width: 1000px) {
    padding: 0 90px;
  }

  @media (max-width: 800px) {
    padding: 0 60px;
  }

  @media (max-width: 768px) {
    padding: 0 35px;
  }
`;

const Layout = ({ children }) => {
  const [pageContext, setPageContext] = useState({
    pageTitle: ``,
    pageType: ``,
    pageColor: ``,
  });

  useEffect(() => {
    document.body.style.backgroundColor = pageContext.pageColor;
  }, [pageContext]);

  useEffect(() => {
    console.log(
      "%cDevelopment: Kieran Startup: www.kieranstartup.co.uk",
      "color:red; font-family:Helvetica Neue;  font-size: 20px"
    );
  }, []);

  return (
    <PageContext.Provider value={[pageContext, setPageContext]}>
      <Location>
        {({ location }) => {
          return (
            <PrismicProvider
              linkResolver={linkResolver}
              internalLinkComponent={({ href, ...props }) => (
                <Link to={href} {...props} />
              )}
            >
              <Container location={location.pathname}>
                <Normalize />
                <GlobalStyles />
                <DefaultSEO />
                <Header location={location} pageContext={pageContext} />
                {/* <AnimatePresence> */}
                {/* <motion.main
                  initial={{
                    opacity: 0,
                    x: -200,
                  }}
                  animate={{
                    opacity: 1,
                    x: 0,
                  }}
                  exit={{
                    opacity: 0,
                    x: 200,
                  }}
                  transition={{
                    type: "spring",
                    mass: 0.35,
                    stiffness: 75,
                    duration: 0.3,
                  }}
                > */}
                <main>{children}</main>
                {/* </motion.main> */}
                {/* </AnimatePresence> */}
              </Container>
            </PrismicProvider>
          );
        }}
      </Location>
    </PageContext.Provider>
  );
};

export default Layout;
