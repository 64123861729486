import React, { useState, useContext, useEffect } from "react";
import { graphql, Link } from "gatsby";
import { PrismicRichText } from "@prismicio/react";
import styled from "styled-components";
import { useMeasure } from "react-use";

// Previews
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";

// Utils
import kebabCase from "lodash.kebabcase";

// Context
import { PageContext } from "../components/context/page-context";

// SEO
import { PageSeo } from "../components/seo/page-seo";

// // SEO
// import { PageSeo } from "../components/seo/seo";

// // Context
// import { PageType } from "../components/context/page-type";

// // Utils
// import kebabCase from "lodash.kebabcase";

// // Icons
// import { Arrow, MobileArrow } from "../components/icons/arrows";

// Components
// import { Audio, Video } from "../components/videos/video";
import { Progress } from "../components/progress/progress";

// Utils
import {
  AudioModule,
  CreditsModule,
  ImageGalleryModule,
  ImageModule,
  ReviewsModule,
  TextModule,
  VideoModule,
  Page,
} from "../components/modules/modules";

// const Page = styled.div`
//   padding: 120px 0;

//   & .module {
//     margin: 60px auto;
//   }

//   & .text-container {
//     & .grid {
//       display: grid;
//       grid-template-columns: 1fr 700px 1fr;
//       grid-column-gap: 50px;
//     }

//     & .inner-text-container {
//       grid-column: 2;

//       & p {
//         font-size: 21px;
//         line-height: 28px;

//         &:first-of-type {
//           margin-top: 0;
//         }

//         &:last-of-type {
//           margin-bottom: 0;
//         }

//         & strong {
//           font-size: 14px;
//           vertical-align: super;

//           color: #bf4945;
//         }
//       }
//     }

//     & .footnotes-container {
//       & p {
//         font-size: 14px;
//         line-height: 20px;

//         color: #bf4945;

//         margin: 0;
//       }
//     }
//   }

//   & .image-container {
//     max-width: 510px;
//   }

//   & .audio-container {
//     max-width: 700px;
//   }

//   & .video-container {
//     max-width: 830px;
//   }
// `;

const Introduction = styled.div`
  max-width: 700px;
  margin: 0 auto 10px auto;

  & .title-container {
    margin: 30px auto 20px auto;
    max-width: 700px;
  }

  & h3 {
    font-size: 14px;
    line-height: 28px;

    text-align: center;
    text-transform: uppercase;
    letter-spacing: 0.9px;

    & .spacer {
      margin: 0 12px;
    }
  }

  & h1 {
    text-align: center;

    &.johnston {
      font-family: "Johnston ITC W01 Bold";
      font-size: 38px;
      line-height: 50px;

      @media (max-width: 768px) {
        font-size: 30px;
        line-height: 37px;
      }
    }

    &.calvert {
      font-family: "Calvert MT W01 Bold";
      font-size: 38px;
      line-height: 50px;

      @media (max-width: 768px) {
        font-size: 30px;
        line-height: 37px;
      }
    }

    &.kabel {
      font-family: "Kabel LT W01 Book";
      font-size: 46px;
      line-height: 50px;

      @media (max-width: 768px) {
        font-size: 38px;
        line-height: 45px;
      }
    }
  }

  & ol {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;

    & li {
      width: fit-content;

      margin: 10px 10px 0 10px;

      & a {
        display: block;
        border: 1px solid #bf4945;
        padding: 0 10px;

        font-size: 11px;
        line-height: 25px;

        text-transform: uppercase;

        color: #bf4945;
        background-color: #fff;

        transition: 300ms color ease, 300ms background-color ease;

        &:hover {
          background-color: #bf4945;
          color: #fff;
        }
      }

      &.artist {
        & a {
          border: 1px solid #82a0aa;

          color: #82a0aa;
          background-color: #fff;

          &:hover {
            background-color: #82a0aa;
            color: #fff;
          }
        }
      }
    }
  }

  @media (max-width: 768px) {
    margin: 0 auto 30px auto;

    & .title-container {
      margin: 70px auto;
    }

    & .subtitle-container {
      & h3 {
        font-size: 12px;
        line-height: 20px;

        & span {
          display: block;
          opacity: 0;
          height: 0;
        }
      }
    }
  }

  // @media (max-width: 768px) {
  //   & ol {
  //     display: block;
  //     flex-direction: unset;
  //     flex-wrap: unset;
  //     justify-content: unset;

  //     & li {
  //       margin: 10px auto;
  //     }
  //   }
  // }
`;

const Conversation = ({ data }) => {
  const [pageContext, setPageContext] = useContext(PageContext);
  const [ref, { x, y, width, height, top, right, bottom, left }] = useMeasure();
  const [randomFont, setRandomFont] = useState(``);

  const randomItem = (items) => items[Math.floor(Math.random() * items.length)];
  const items = [`johnston`, `calvert`, `kabel`];

  useEffect(() => {
    setRandomFont(randomItem(items));
  }, []);

  useEffect(() => {
    setPageContext({
      pageTitle: data.prismicConversation.data.title.text,
      pageColor: `#fff`,
      pageType: `page`,
    });
  }, [data]);

  const content = data.prismicConversation.data.body.map((content, index) => {
    if (content.slice_type === "image") {
      return (
        <ImageModule
          key={`single_image_container_${index}_${content.id}`}
          content={content}
          index={index}
        />
      );
    }

    if (content.slice_type === "text") {
      return (
        <TextModule
          key={`single_text_container_${index}_${content.id}`}
          content={content}
          index={index}
        />
      );
    }

    if (content.slice_type === "audio") {
      return (
        <AudioModule
          key={`single_image_container_${index}_${content.id}`}
          content={content}
          index={index}
        />
      );
    }

    if (content.slice_type === "video") {
      return (
        <VideoModule
          key={`single_video_container_${index}_${content.id}`}
          content={content}
          index={index}
        />
      );
    }
  });

  const tags = data.prismicConversation.tags.map((tag, index) => (
    <li key={`single_tag_${index}`} className="tag">
      <Link to={`/${kebabCase(tag)}/`}>{tag}</Link>
    </li>
  ));

  const artistTags = data.prismicConversation.data.artist_tag
    .filter((artist) => artist.artist !== null)
    .map((artist, index) => (
      <li key={`single_tag_${index}`} className="tag artist">
        <Link to={`/${kebabCase(artist.artist)}/`}>{artist.artist}</Link>
      </li>
    ));

  return (
    <>
      <PageSeo
        seoTitle={`${data.prismicConversation.data.title.text} - George Vasey`}
        seoText={null}
        seoImage={null}
      />

      <Page ref={ref}>
        <Introduction>
          <div className="subtitle-container">
            <h3>
              Conversation
              {data.prismicConversation.data.date !== null && (
                <>
                  <span className="spacer">·</span>
                  {data.prismicConversation.data.date}
                </>
              )}
            </h3>
          </div>

          {randomFont !== `` && (
            <PrismicRichText
              field={data.prismicConversation.data.title.richText}
              components={{
                heading1: ({ children }) => (
                  <div
                    className="title-container"
                    key={`single_writing_title_${data.prismicConversation.id}`}
                  >
                    <h1 className={randomFont}>{children}</h1>
                  </div>
                ),
              }}
            />
          )}

          <ol>
            {tags}
            {artistTags}
          </ol>
        </Introduction>

        {content}

        <Progress docHeight={bottom + top} />
      </Page>
    </>
  );
};

export const query = graphql`
  query Conversation($uid: String!) {
    prismicConversation(uid: { eq: $uid }) {
      _previewable
      url
      tags
      data {
        title {
          richText
          text
        }
        date(formatString: "D MMM YYYY")
        artist_tag {
          artist
        }
        content_type {
          document {
            ... on PrismicContentType {
              id
              data {
                content_type
                color
              }
            }
          }
        }
        body {
          ... on PrismicConversationDataBodyImage {
            id
            slice_type
            primary {
              caption {
                richText
              }
              image {
                alt
                fluid {
                  aspectRatio
                  src
                  srcSet
                }
                dimensions {
                  width
                  height
                }
              }
            }
          }
          ... on PrismicConversationDataBodyText {
            id
            slice_type
            primary {
              text {
                html
                richText
              }
            }
            items {
              footnote {
                richText
              }
            }
          }

... on PrismicConversationDataBodyAudio {
            id
            slice_type
            primary {
              audio {
                id
                html
                embed_url
              }
              caption {
                richText
              }
            }
          }

... on PrismicConversationDataBodyVideo {
            id
            slice_type
            primary {
              video {
                id
                html
                embed_url
              }
              caption {
                richText
              }
            }
          }
        }
      }
    }
  }
`;


export default withPrismicPreview(Conversation);
