import React, { useState, useEffect } from "react";
import styled from "styled-components";

// Hooks
import { useMediaQuery } from "../hooks/useMediaQuery";

// Components
import { DesktopMenu } from "../navigation/desktop-menu";
import { MobileMenu } from "../navigation/mobile-menu";

const HeaderWrapper = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;

  display: flex;
  flex-direction: row;
  justify-content: space-between;

  padding: 0 20px;

  z-index: 200;

  background-color: ${(props) => props.backgroundColor};
  transition: 350ms background-color ease;

  &.page-type-info {
    color: #fff;

    & ol {
      & li {
        & a {
          color: #fff;
        }
      }
    }

    & button {
      color: #fff;
    }
  }

  @media (max-width: 768px) {
    padding: 0;
    line-height: 65px;

    border-bottom: 1px solid #000;

    &.page-type-info {
      border-bottom: 1px solid #fff;

      & nav {
        background-color: ${(props) => props.backgroundColor};

        & button {
          & svg.open-toggle {
            & path {
              stroke: #fff;
            }
          }
        }
      }
    }
  }
`;

export const Header = ({ location, pageContext }) => {
  // Media Query
  let isDesktop = useMediaQuery("(min-width: 769px)");

  return (
    <HeaderWrapper
      backgroundColor={pageContext.pageColor}
      className={`page-type-${pageContext.pageType}`}
    >
      {isDesktop ? (
        <DesktopMenu location={location} pageType={pageContext.pageType} />
      ) : (
        <MobileMenu location={location} pageType={pageContext.pageType} />
      )}
    </HeaderWrapper>
  );
};
