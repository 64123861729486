import React, { useState, useContext, useEffect } from "react";
import { graphql, Link } from "gatsby";
import styled from "styled-components";

// Previews
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";

// Utils
import kebabCase from "lodash.kebabcase";

// Context
import { PageContext } from "../components/context/page-context";

// SEO
import { PageSeo } from "../components/seo/page-seo";

const Page = styled.div`
  padding: 120px 0;

  @media (max-width: 768px) {
    padding: 90px 0;
  }

  & ol {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;

    & li {
      width: fit-content;

      margin: 23px;

      & a {
        display: block;

        border: 1px solid #bf4945;
        padding: 0 10px;

        text-transform: uppercase;

        color: #bf4945;
        background-color: #fff;

        transition: 300ms color ease, 300ms background-color ease;

        font-size: 14px;
        line-height: 25px;

        &:hover {
          background-color: #bf4945;
          color: #fff;
        }
      }
    }
  }

  @media (max-width: 768px) {
    & ol {
      & li {
        margin: 10px;
      }
    }
  }
`;

const Index = ({ data }) => {
  const [pageContext, setPageContext] = useContext(PageContext);

  useEffect(() => {
    setPageContext({
      pageTitle: ``,
      pageColor: `#fff`,
      pageType: `home`,
    });
  }, []);

  const allConversationTags = Array.from(
    new Set(
      data.allPrismicConversation.nodes.map((item, index) => {
        return item.tags.map((tag) => tag.toLowerCase().trim());
      })
    )
  ).flat();

  const allCuratingTags = Array.from(
    new Set(
      data.allPrismicCurating.nodes.map((item, index) => {
        return item.tags.map((tag) => tag.toLowerCase().trim());
      })
    )
  ).flat();

  //
  const allWritingTags = Array.from(
    new Set(
      data.allPrismicWriting.nodes.map((item, index) => {
        return item.tags.map((tag) => tag.toLowerCase().trim());
      })
    )
  ).flat();

  const allTags = [
    ...allConversationTags,
    ...allCuratingTags,
    ...allWritingTags,
  ];

  const allDistinctTags = Array.from(new Set(allTags));

  const content = allDistinctTags.map((tag, index) => (
    <li key={`single_tag_${index}`} className="tag">
      <Link to={`/${kebabCase(tag)}/`}>{tag}</Link>
    </li>
  ));

  return (
    <>
      <PageSeo seoTitle={`George Vasey`} seoText={null} seoImage={null} />
      <Page>
        <ol>{content}</ol>
      </Page>
    </>
  );
};
export default withPrismicPreview(Index);

export const query = graphql`
  {
    allPrismicConversation {
      nodes {
        tags
      }
    }
    allPrismicCurating {
      nodes {
        tags
      }
    }
    allPrismicWriting {
      nodes {
        tags
      }
    }
  }
`;
