exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-artists-js": () => import("./../../../src/pages/artists.js" /* webpackChunkName: "component---src-pages-artists-js" */),
  "component---src-pages-conversations-js": () => import("./../../../src/pages/conversations.js" /* webpackChunkName: "component---src-pages-conversations-js" */),
  "component---src-pages-curating-js": () => import("./../../../src/pages/curating.js" /* webpackChunkName: "component---src-pages-curating-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-preview-js": () => import("./../../../src/pages/preview.js" /* webpackChunkName: "component---src-pages-preview-js" */),
  "component---src-pages-writing-js": () => import("./../../../src/pages/writing.js" /* webpackChunkName: "component---src-pages-writing-js" */),
  "component---src-templates-artist-js": () => import("./../../../src/templates/artist.js" /* webpackChunkName: "component---src-templates-artist-js" */),
  "component---src-templates-conversation-js": () => import("./../../../src/templates/conversation.js" /* webpackChunkName: "component---src-templates-conversation-js" */),
  "component---src-templates-curating-js": () => import("./../../../src/templates/curating.js" /* webpackChunkName: "component---src-templates-curating-js" */),
  "component---src-templates-tag-js": () => import("./../../../src/templates/tag.js" /* webpackChunkName: "component---src-templates-tag-js" */),
  "component---src-templates-writing-js": () => import("./../../../src/templates/writing.js" /* webpackChunkName: "component---src-templates-writing-js" */)
}

