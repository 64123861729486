import React, { useState, useContext, useEffect } from "react";
import { graphql, Link } from "gatsby";
import { PrismicRichText } from "@prismicio/react";
import styled from "styled-components";
import { useMeasure } from "react-use";

// Previews
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";

// Utils
import kebabCase from "lodash.kebabcase";

// Context
import { PageContext } from "../components/context/page-context";

// Components
import { Progress } from "../components/progress/progress";
import { Lightbox } from "../components/images/gallery";

// SEO
import { PageSeo } from "../components/seo/page-seo";

// Utils
import {
  AudioModule,
  CreditsModule,
  ImageGalleryModule,
  ImageModule,
  ReviewsModule,
  TextModule,
  VideoModule,
  Page,
} from "../components/modules/modules";

const Introduction = styled.div`
  margin: 0 auto 27px auto;

  & .title-container {
    margin: 0 auto 20px auto;
    max-width: 700px;

    & h1,
    & h3 {
      font-size: 14px;
      line-height: 28px;

      text-align: center;
      text-transform: uppercase;
      letter-spacing: 0.9px;

      & .spacer {
        margin: 0 12px;
      }
    }
  }

  & ol {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;

    & li {
      width: fit-content;

      margin: 10px 10px 0 10px;

      & a {
        display: block;
        border: 1px solid #bf4945;
        padding: 0 10px;

        font-size: 11px;
        line-height: 25px;

        text-transform: uppercase;

        color: #bf4945;
        background-color: #fff;

        transition: 300ms color ease, 300ms background-color ease;

        &:hover {
          background-color: #bf4945;
          color: #fff;
        }
      }

      &.artist {
        & a {
          border: 1px solid #82a0aa;

          color: #82a0aa;
          background-color: #fff;

          &:hover {
            background-color: #82a0aa;
            color: #fff;
          }
        }
      }
    }
  }

  @media (max-width: 768px) {
    & .title-container {
      & h3 {
        font-size: 12px;
        line-height: 20px;

        & span {
          display: block;
          opacity: 0;
          height: 0;
        }
      }

      & h1 {
        margin: 1em 0 0 0;
      }
    }
  }

  // @media (max-width: 768px) {
  //   & ol {
  //     display: block;
  //     flex-direction: unset;
  //     flex-wrap: unset;
  //     justify-content: unset;

  //     & li {
  //       margin: 10px auto;
  //     }
  //   }
  // }
`;

const Curating = ({ data }) => {
  const [pageContext, setPageContext] = useContext(PageContext);
  const [ref, { x, y, width, height, top, right, bottom, left }] = useMeasure();

  // Lightbox
  const [isLightboxOpen, setIsLightboxOpen] = useState(false);
  const [activeSlideNumber, setActiveSlideNumber] = useState(0);
  const [galleryContent, setGalleryContent] = useState([]);

  useEffect(() => {
    setPageContext({
      pageTitle: data.prismicCurating.data.title.text,
      pageColor: `#fff`,
      pageType: `page`,
    });
  }, [data]);

  const content = data.prismicCurating.data.body.map((content, index) => {
    if (content.slice_type === "image_gallery") {
      return (
        <ImageGalleryModule
          key={`single_image_gallery_container_${index}_${content.id}`}
          setIsLightboxOpen={setIsLightboxOpen}
          setActiveSlideNumber={setActiveSlideNumber}
          setGalleryContent={setGalleryContent}
          content={content}
          index={index}
        />
      );
    }

    if (content.slice_type === "image") {
      return (
        <ImageModule
          key={`single_image_container_${index}_${content.id}`}
          content={content}
          index={index}
        />
      );
    }

    if (content.slice_type === "text") {
      return (
        <TextModule
          key={`single_text_container_${index}_${content.id}`}
          content={content}
          index={index}
        />
      );
    }

    if (content.slice_type === "audio") {
      return (
        <AudioModule
          key={`single_image_container_${index}_${content.id}`}
          content={content}
          index={index}
        />
      );
    }

    if (content.slice_type === "video") {
      return (
        <VideoModule
          key={`single_video_container_${index}_${content.id}`}
          content={content}
          index={index}
        />
      );
    }

    if (content.slice_type === "reviews") {
      return (
        <ReviewsModule
          key={`single_review_container_${index}_${content.id}`}
          content={content}
          index={index}
        />
      );
    }

    if (content.slice_type === "credits") {
      return (
        <CreditsModule
          key={`single_credits_container_${index}_${content.id}`}
          content={content}
          index={index}
        />
      );
    }
  });

  const tags = data.prismicCurating.tags.map((tag, index) => (
    <li key={`single_tag_${index}`} className="tag">
      <Link to={`/${kebabCase(tag)}/`}>{tag}</Link>
    </li>
  ));

  const artistTags = data.prismicCurating.data.artist_tag
    .filter((artist) => artist.artist !== null)
    .map((artist, index) => (
      <li key={`single_tag_${index}`} className="tag artist">
        <Link to={`/${kebabCase(artist.artist)}/`}>{artist.artist}</Link>
      </li>
    ));

  return (
    <>
      <PageSeo
        seoTitle={`${data.prismicCurating.data.title.text} - George Vasey`}
        seoText={null}
        seoImage={null}
      />

      <Page ref={ref}>
        <Introduction>
          <div className="title-container">
            <h3>
              {data.prismicCurating.data.content_type.document !== null ? (
                <>
                  {
                    data.prismicCurating.data.content_type.document.data
                      .content_type
                  }
                  <span className="spacer">·</span>
                </>
              ) : (
                ``
              )}
              {data.prismicCurating.data.dates.text}
            </h3>
            <h1>{data.prismicCurating.data.title.text}</h1>
          </div>

          <ol>
            {tags}
            {artistTags}
          </ol>
        </Introduction>
        {content}

        {/* <div className="related-tags-container">
          <h2>Related Tags</h2>
        </div> */}

        <Progress docHeight={bottom + top} />
        <div className="spacer" />
      </Page>

      {isLightboxOpen && (
        <Lightbox
          isLightboxOpen={isLightboxOpen}
          setIsLightboxOpen={setIsLightboxOpen}
          images={galleryContent}
          activeSlideNumber={activeSlideNumber}
        />
      )}
    </>
  );
};

export const query = graphql`
  query Curating($uid: String!) {
    prismicCurating(uid: { eq: $uid }) {
      _previewable
      url
      tags
      id
      data {
        title {
          richText
          text
        }
        dates {
          text
        }
        artist_tag {
          artist
        }
        content_type {
          document {
            ... on PrismicContentType {
              id
              data {
                content_type
                color
              }
            }
          }
        }
        body {
          ... on PrismicCuratingDataBodyImage {
            id
            slice_type
            primary {
              image {
                alt
                fluid {
                  aspectRatio
                  src
                  srcSet
                }
                dimensions {
                  width
                  height
                }
              }
              caption {
                richText
              }
            }
          }
          ... on PrismicCuratingDataBodyText {
            id
            slice_type
            primary {
              text {
                html
                richText
              }
            }
            items {
              footnote {
                richText
              }
            }
          }

          ... on PrismicCuratingDataBodyReviews {
            id
            slice_type
            items {
              link {
                richText
              }
            }
          }
          ... on PrismicCuratingDataBodyCredits {
            id
            items {
              title1
              credit {
                richText
              }
            }
            slice_type
          }
          ... on PrismicCuratingDataBodyImageGallery {
            id
            slice_type
            items {
              image {
                alt
                fluid {
                  srcSet
                  src
                  aspectRatio
                }
                dimensions {
                  width
                  height
                }
              }
            }
          }
          ... on PrismicCuratingDataBodyAudio {
            id
            slice_type
            primary {
              audio {
                id
                html
                embed_url
              }
              caption {
                richText
              }
            }
          }

          ... on PrismicCuratingDataBodyVideo {
            id
            slice_type
            primary {
              video {
                id
                html
                embed_url
              }
              caption {
                richText
              }
            }
          }
        }
      }
    }
  }
`;

export default withPrismicPreview(Curating);
