import React, {
  useState,
  useRef,
  useEffect,
  useCallback,
  useLayoutEffect,
} from "react";
import styled from "styled-components";
import { PrismicRichText } from "@prismicio/react";
import { useMeasure } from "react-use";

// Components
import { Audio, Video } from "../videos/video";
import { AspectRatioImageContainer } from "../containers/aspect-ratio-image-container";
import { SingleImageLightbox } from "../images/lightbox";

// Utils
import { ImageOrientation } from "../utils/image-orientation";
import { splitToChunks } from "../utils/split-to-chunks";

const PositionedFootnote = styled.div`
  position: absolute;
  top: calc(${(props) => props.top}px + 40px - 2em);
`;

export const VideoModule = ({ content, index }) => {
  return (
    <div className="module video-container">
      <Video url={content.primary.video.embed_url} />

      <div className="caption">
        <PrismicRichText field={content.primary.caption.richText} />
      </div>
    </div>
  );
};

export const AudioModule = ({ content, index }) => {
  return (
    <div className="module audio-container">
      <Audio url={content.primary.audio.embed_url} />

      <div className="caption">
        <PrismicRichText field={content.primary.caption.richText} />
      </div>
    </div>
  );
};

export const ImageModule = ({ content, index }) => {
  const [isLightboxOpen, setIsLightboxOpen] = useState(false);

  return (
    <>
      <div className="module image-container">
        {content.primary.image.fluid !== null && (
          <img
            srcSet={content.primary.image.fluid.srcSet}
            src={content.primary.image.fluid.src}
            alt={content.primary.image.alt}
            loading={`lazy`}
            onClick={() => setIsLightboxOpen(true)}
          />
        )}

        <div className="caption">
          <PrismicRichText field={content.primary.caption.richText} />
        </div>
      </div>

      {isLightboxOpen && (
        <SingleImageLightbox
          content={content}
          isLightboxOpen={isLightboxOpen}
          setIsLightboxOpen={setIsLightboxOpen}
        />
      )}
    </>
  );
};

// function useClientRect() {
//   const [rect, setRect] = useState(null);
//   const ref = useCallback((node) => {
//     if (node !== null) {
//       console.log(node);
//       setRect(node.offsetTop);
//     }
//   }, []);
//   return [rect, ref];
// }

export const TextModule = ({ content, index }) => {
  const [allFootnotePositions, setAllFootnotePositions] = useState([]);
  const [isInitialRender, setIsInitialRender] = useState(true);

  // console.log(allFootnotePositions);

  const InlineFootnote = ({ children, updatePositions }) => {
    // const [rect, ref] = useClientRect();

    // useEffect(() => {
    //   if (rect !== null) {
    //     updatePositions(rect);
    //   }
    // }, [rect]);

    return (
      <strong className="inline-footnote">
        {/* // <strong className="inline-footnote" ref={ref}> */}
        {children}
      </strong>
    );
  };

  // const SingleExternalFootnote = ({
  //   footnote,
  //   index,
  //   allFootnotePositions,
  // }) => {
  //   return (
  //     <PrismicRichText
  //       field={footnote.footnote.richText}
  //       components={{
  //         paragraph: ({ children }) => (
  //           <PositionedFootnote
  //             top={allFootnotePositions[index]}
  //             className="footnote"
  //             key={`single_footnote_text_${content.id}`}
  //           >
  //             <p>{children}</p>
  //           </PositionedFootnote>
  //         ),
  //       }}
  //     />
  //   );
  // };

  // const footnotes = content.items.map((footnote, index) => (
  //   <SingleExternalFootnote
  //     footnote={footnote}
  //     index={index}
  //     key={`single_eternal_footnote_${index}_${content.id}`}
  //     allFootnotePositions={allFootnotePositions}
  //   />
  // ));

  // const splitFootnotes = splitToChunks(footnotes, 2);

  // const updatePositions = (position) => {
  //   if (isInitialRender) {
  //     setIsInitialRender(false);

  //     setAllFootnotePositions((oldArray) => [...oldArray, position]);
  //   }
  // };

  return (
    <div className="module text-container">
      <div className="grid">
        {/* <div className="footnotes-container left">{splitFootnotes[0]}</div> */}

        <div className="inner-text-container">
          <PrismicRichText
            field={content.primary.text.richText}
            components={{
              paragraph: ({ children }) => <p>{children}</p>,
              strong: ({ children }) => (
                <InlineFootnote
                  children={children}
                  // updatePositions={updatePositions}
                />
              ),
            }}
          />
        </div>

        {/* <div className="footnotes-container right">{splitFootnotes[1]}</div> */}
      </div>
    </div>
  );
};

export const ImageGalleryModule = ({
  content,
  index,
  setIsLightboxOpen,
  setActiveSlideNumber,
  setGalleryContent,
}) => {
  const images = content.items
    .filter((image) => image.image.fluid !== null)
    .map((image, index) => (
      <div
        className={`single-gallery-image-container image-${ImageOrientation(
          image.image
        )}`}
        key={`single_gallery_image_${index}_${content.id}`}
        onClick={() => {
          setIsLightboxOpen(true);
          setActiveSlideNumber(index);
          setGalleryContent(
            content.items.filter((image) => image.image.fluid !== null)
          );
        }}
      >
        <AspectRatioImageContainer image={image.image}>
          <img
            srcSet={image.image.fluid.srcSet}
            src={image.image.fluid.src}
            alt={image.image.alt}
            loading={`lazy`}
          />
        </AspectRatioImageContainer>
      </div>
    ));

  return <div className="module image-gallery-container">{images}</div>;
};

export const ReviewsModule = ({ content, index }) => {
  const reviews = content.items.map((review, index) => (
    <li key={`single_review_${index}_${content.id}`}>
      <PrismicRichText field={review.link.richText} />
    </li>
  ));

  return (
    <div className="module reviews-container">
      <p className="title">
        Reviews<span className="mobile-colon">:</span>
      </p>
      <ol>{reviews}</ol>
    </div>
  );
};

export const CreditsModule = ({ content, index }) => {
  const credits = content.items.map((credit, index) => (
    <li key={`single_credit_${index}_${content.id}`}>
      <p>
        {credit.title1}
        <span className="mobile-colon">:</span>
      </p>
      <PrismicRichText field={credit.credit.richText} />
    </li>
  ));

  return (
    <div className="module credits-container">
      <ol>{credits}</ol>
    </div>
  );
};

export const Page = styled.div`
  padding: 120px 0 0 0;

  @media (max-width: 768px) {
    padding: 90px 0 0 0;
  }

  & .spacer {
    height: 60px;

    @media (max-width: 768px) {
      height: 40px;
    }
  }

  & .module {
    margin: 40px auto;

    @media (max-width: 768px) {
      margin: 30px auto;
    }
  }

  & .image-gallery-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 60px;
    grid-row-gap: 20px;

    align-items: center;

    max-width: 830px;

    & .single-gallery-image-container {
      &.image-portrait {
        padding: 0 40px;
      }

      & img {
        cursor: pointer;
        transition: 250ms opacity ease;

        &:hover {
          opacity: 0.75;
        }
      }
    }

    @media (max-width: 768px) {
      display: block;
      grid-template-columns: unset;
      grid-column-gap: unset;
      grid-row-gap: unset;

      & .single-gallery-image-container {
        margin: 0 0 20px 0;
      }
    }
  }

  & .text-container {
    position: relative;

    & .grid {
      display: grid;
      grid-template-columns: 1fr 700px 1fr;
      grid-column-gap: 50px;

      position: relative;

      @media (max-width: 768px) {
        display: block;
        grid-template-columns: unset;
        grid-column-gap: unset;
      }
    }

    & .inner-text-container {
      position: relative;
      grid-column: 2;

      & p {
        font-size: 21px;
        line-height: 28px;

        // position: relative;
        display: block;

        &:first-of-type {
          margin-top: 0;
        }

        &:last-of-type {
          margin-bottom: 0;
        }

        & strong {
          font-size: 14px;
          vertical-align: super;

          color: #bf4945;

          position: relative;
          // display: inline-block;

          @media (max-width: 768px) {
            font-size: 12px;
            line-height: 20px;
          }
        }

        & a {
          color: #bf4945;
        }

        @media (max-width: 768px) {
          font-size: 15px;
          line-height: 20px;
        }
      }
    }

    & .footnotes-container {
      position: relative;

      & p {
        font-size: 14px;
        line-height: 20px;

        color: #bf4945;

        margin: 0 0 1em 0;
      }
    }
  }

  & .image-container {
    max-width: 510px;
    margin: 60px auto;

    & img {
      cursor: pointer;
      transition: 250ms opacity ease;

      &:hover {
        opacity: 0.75;
      }
    }

    @media (max-width: 768px) {
      margin: 30px auto;
    }
  }

  & .audio-container {
    max-width: 700px;
  }

  & .video-container {
    max-width: 830px;
  }

  & .reviews-container {
    display: grid;
    grid-template-columns: 190px 1fr;
    grid-column-gap: 10px;

    max-width: 700px;

    border-top: 1px solid #000;
    padding: 8px 0;

    & p {
      font-size: 14px;
      line-height: 28px;

      text-transform: uppercase;
      letter-spacing: 0.9px;

      margin: 0;

      & span {
        display: none;
      }
    }

    @media (max-width: 768px) {
      display: block;
      grid-template-columns: unset;
      grid-column-gap: unset;

      border-top: 0;

      & p {
        font-size: 12px;
        line-height: 20px;

        & span {
          display: inline-block;
        }
      }

      & ol {
        padding: 0 0 0 40px;
      }
    }
  }

  & .credits-container {
    max-width: 700px;

    border-top: 1px solid #000;
    border-bottom: 1px solid #000;

    padding: 8px 0;

    & ol {
      & li {
        display: grid;
        grid-template-columns: 190px 1fr;
        grid-column-gap: 10px;

        & p {
          font-size: 14px;
          line-height: 28px;

          text-transform: uppercase;
          letter-spacing: 0.9px;

          margin: 0;

          & span {
            display: none;
          }
        }
      }
    }

    @media (max-width: 768px) {
      border-bottom: 0;
      padding: 8px 0 0 0;

      & ol {
        & li {
          display: block;
          grid-template-columns: unset;
          grid-column-gap: unset;

          margin: 0 0 1em 0;

          &:last-of-type {
            margin: 0;
          }

          & p {
            font-size: 12px;
            line-height: 20px;

            & span {
              display: inline-block;
            }

            &:last-of-type {
              padding: 0 0 0 40px;
            }
          }
        }
      }
    }
  }

  & .module.reviews-container,
  & .module.credits-container {
    margin: 0 auto;
  }

  & .related-tags-container {
    max-width: 550px;
    margin: 60px auto;

    & h2 {
      text-align: center;
    }

    @media (max-width: 768px) {
      border-top: 1px solid #000;

      margin: 30px auto;
      padding: 20px 0 0 0;
    }
  }
`;
