exports.linkResolver = (doc) => {
  switch (doc.type) {
    case "about": {
      return `/${doc.uid}/`;
    }

    case "writing": {
      return `/writing/${doc.uid}/`;
    }

    case "curating": {
      return `/curating/${doc.uid}/`;
    }

    case "conversation": {
      return `/conversations/${doc.uid}/`;
    }

    // default:
    //   return `/${doc.uid}/`;
  }
};
