import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useWindowScroll, useWindowSize } from "react-use";

const ProgressBar = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;

  background: linear-gradient(
    to right,
    rgba(191, 73, 69, 1) ${(props) => props.scroll}%,
    transparent 0
  );

  // width: ${(props) => props.scroll}%;
  // background-color: rgba(191, 73, 69, 1);
  // transition: 25ms width ease;

  width: 100%;
  height: 6px;
  z-index: 3;
`;

// https://www.digitalocean.com/community/tutorials/react-progress-bar-on-scroll
export const Progress = ({ docHeight }) => {
  const { width, height } = useWindowSize();
  const { x, y } = useWindowScroll();
  const [scrollPosition, setScrollPosition] = useState(0);
  const [totalScrollHeight, setTotalScrollHeight] = useState(0);

  useEffect(() => {
    setTotalScrollHeight(docHeight - height);
  }, [docHeight, height]);

  useEffect(() => {
    setScrollPosition(Math.floor((y / totalScrollHeight) * 100));
  }, [y, totalScrollHeight]);

  return <ProgressBar scroll={scrollPosition} />;
};
