import React from "react";

export const OpenMenu = () => (
  <svg
    width="27"
    height="11"
    viewBox="0 0 27 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="open-toggle"
  >
    <path
      d="M0.5 0.5H26.5M0.5 5H26.5M0.5 10H26.5"
      stroke="#231F20"
      strokeLinecap="round"
    />
  </svg>
);
