import React from "react";
import ReactPlayer from "react-player";

// Components
import { AspectRatioImageContainer } from "../containers/aspect-ratio-image-container";

export const Video = ({ url, width, height }) => {
  return (
    <div className="video-container">
      <AspectRatioImageContainer image={null} padding={56.25}>
        <ReactPlayer
          url={url}
          width="100%"
          height="100%"
          config={{
            youtube: {
              playerVars: { showinfo: 0, modestbranding: 1, controls: 0 },
            },
          }}
        />
      </AspectRatioImageContainer>
    </div>
  );
};

//https://github.com/cookpete/react-player/issues/321
export const Audio = ({ url, width, height }) => {
  return (
    <div className="audio-container">
      <ReactPlayer
        url={`${url}?color=0d0d0d&sharing=false&show_artwork=false&show_playcount=false&show_user=false&buying=false&download=false`}
        width="100%"
        height="100%"
        config={{
          soundcloud: {
            options: {
              color: `#0d0d0d`,
              sharing: false,
              show_artwork: true,
              show_playcount: false,
              show_user: false,
              buying: false,
              download: false,
            },
          },
        }}
      />
    </div>
  );
};
